<template>
  <!-- 包车规则配置 -->
  <div class="carterConfig">
    <div class="rule-tabs">
      <div class="rule-tabs-title">
        <div
          v-for="(item, index) in tabsList"
          :key="item.text"
          :class="item.class"
          @click="tabClick(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="rule-tabs-content">
        <el-button
          v-if="operAtionIndex == 1"
          class="tabs-add"
          size="small"
          sort="primary"
          @click="addFun"
          >添加</el-button
        >
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :operation-width="operAtionIndex == 1 ? '110' : '70'"
          :operation="true"
          :col-class-type="true"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : true
              "
              type="text"
              size="small"
              sort="primary"
              @click="editFun(scope.scopeRow)"
              >编辑</el-button
            >
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : false
              "
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </Table>
      </div>
    </div>

    <!-- 弹窗 -->
    <OperAtion
      v-if="operAtionShow"
      :header-text="headerText"
      :popup-num="popupNum"
      :oper-ation-data="operAtionData"
      @confirmFun="confirmFun"
      @closePopupFun="closePopupFun"
    />
    <charterPopup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFormFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="值：" prop="formValue">
          <el-input
            v-if="type == 'number'"
            v-model="Form.formValue"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            type="number"
          ></el-input>
          <el-input
            v-else-if="type == 'string'"
            v-model="Form.formValue"
          ></el-input>
          <el-select v-else v-model="Form.formValue">
            <el-option
              v-for="(i, ind) in list"
              :key="ind"
              :label="i.desc"
              :value="i.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  getCharteredAPI,
  roundTripPricingValue,
  drivingRegulationAPI,
  postCharteredAPI,
  getRrefundTicketRegulationAPI,
  putCharteredAPI,
  delCharteredAPI,
  putCharteredTicketRegulationAPI,
  putCharteredDrivingRegulationAPI,
} from "@/api/lib/api.js";
export default {
  props: {
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      type: "",
      show: false,
      Form: {
        id: "",
        formValue: null,
      },
      rules: {
        formValue: [{ required: true, message: "值不能为空", trigger: "blur" }],
      },
      operAtionShow: false,
      headerText: "",
      popupNum: null,
      tableData: [],
      titleName: [
        {
          title: "规则代码",
          props: "regulationCode",
        },
        {
          title: "规则名",
          props: "regulationName",
        },
        {
          title: "值",
          SpecialJudgment: (res) => {
            if (res.regulationCode == "roundTripPricingMethod")
              return res.regulationValueDescribe;
            else return res.regulationValue;
          },
        },
        {
          title: "单位",
          props: "unitType",
          SpecialJudgment: (res) => {
            return res === 0
              ? "天"
              : res === 1
              ? "分钟"
              : res === 2
              ? "人"
              : res === 3
              ? "小时"
              : res === 4
              ? "个"
              : "";
          },
        },
      ],
      operAtionIndex: 0,
      tabsList: [
        { class: "tabs tabs-active", text: "预订" },
        { class: "tabs", text: "退订" },
        { class: "tabs", text: "行车" },
      ],
      list: [],
    };
  },
  watch: {
    channelNo() {
      this.renderDataA();
    },
  },
  mounted() {
    this.renderDataA();
  },
  methods: {
    clearForm() {
      this.show = false;
    },
    // 添加按钮点击
    addFun() {
      if (this.tableData.length == 0) {
        this.operAtionData = {
          departureTimeStart: 0,
        };
      } else {
        this.operAtionData = {
          departureTimeStart:
            this.tableData[this.tableData.length - 1].departureTimeEnd,
        };
      }
      this.headerText = "新增";
      this.popupNum = 6;
      this.operAtionShow = true;
    },
    // 弹窗回调2
    confirmFormFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.Form);
          // this.Form.formValue = this.Form.formValue.replace(".", "");
          if (this.operAtionIndex == 0) {
            if (Array.isArray(obj.formValue)) {
              const arr = obj.formValue.map((i) => {
                let data = this.list.find((e) => e.desc == i);
                if (data) return data.code;
              });
              obj.formValue = arr.reduce((c, p) => {
                const t = c + p;
                return t;
              });
            }
            putCharteredTicketRegulationAPI({
              id: obj.id,
              regulationValue: obj.formValue,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.show = false;
                this.$message.success("修改成功");
                this.renderDataA();
              }
            });
          } else if (this.operAtionIndex == 2) {
            putCharteredDrivingRegulationAPI({
              id: obj.id,
              regulationValue: obj.formValue,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.show = false;
                this.$message.success("修改成功");
                this.renderDataC();
              }
            });
          }
        }
      });
    },
    confirmFun(val) {
      if (
        val.departureTimeStart === "" ||
        val.departureTimeStart == undefined ||
        val.departureTimeEnd === "" ||
        val.departureTimeEnd == undefined ||
        val.chargeRate === "" ||
        val.chargeRate == undefined
      ) {
        this.$message.error("请填写必填项");
      } else {
        if (this.headerText == "新增") {
          postCharteredAPI({
            regulationName: val.regulationName,
            departureTimeStart: val.departureTimeStart,
            departureTimeEnd: val.departureTimeEnd,
            chargeRate: val.chargeRate,
            channelNo: this.channelNo,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.operAtionShow = false;
              this.$message.success("添加成功");
              this.renderDataB();
            }
          });
        } else {
          putCharteredAPI({
            id: val.id,
            regulationName: val.regulationName,
            departureTimeStart: val.departureTimeStart,
            departureTimeEnd: val.departureTimeEnd,
            chargeRate: val.chargeRate,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.operAtionShow = false;
              this.$message.success("修改成功");
              this.renderDataB();
            }
          });
        }
      }
    },
    // 点击表格编辑按钮
    editFun(val) {
      this.headerText = "编辑";
      if (this.operAtionIndex == 1) {
        this.operAtionData = val;
        this.operAtionShow = true;
        this.popupNum = 6;
      } else if (this.operAtionIndex == 0 || this.operAtionIndex == 2) {
        if (this.operAtionIndex == 0) {
          if (val.regulationCode == "roundTripPricingMethod") {
            this.type = "select";
            roundTripPricingValue().then((res) => {
              if (res.code == "SUCCESS") {
                this.list = res.data;
              }
            });
            this.$nextTick(() => {
              this.Form.formValue = val.regulationValue;
            });
          } else {
            this.type = "number";
          }
        } else {
          this.type = "string";
        }
        this.show = true;
        this.Form.id = val.id;
        this.Form.formValue = val.regulationValue;
      }
    },
    // 删除按钮点击
    deleteFun(val) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          delCharteredAPI({ id: val.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderDataB();
            }
          });
        })
        .catch(() => {});
    },
    // 弹窗取消回调
    closePopupFun() {
      this.operAtionShow = false;
    },
    tabClick(index, flag) {
      this.operAtionIndex = index;
      this.tabsList.map((item) => {
        item.class = "tabs";
      });
      this.tabsList[index].class = "tabs tabs-active";
      switch (index) {
        case 0:
          this.titleName = [
            {
              title: "规则代码",
              props: "regulationCode",
            },
            {
              title: "规则名",
              props: "regulationName",
            },
            {
              title: "值",
              SpecialJudgment: (res) => {
                if (res.regulationCode == "roundTripPricingMethod")
                  return res.regulationValueDescribe;
                else return res.regulationValue;
              },
            },
            {
              title: "单位",
              props: "unitType",
              SpecialJudgment: (res) => {
                return res === 0
                  ? "天"
                  : res === 1
                  ? "分钟"
                  : res === 2
                  ? "人"
                  : res === 3
                  ? "小时"
                  : res === 4
                  ? "个"
                  : "";
              },
            },
          ];
          !flag && this.renderDataA();
          break;
        case 1:
          this.titleName = [
            {
              title: "发车前时间区间起（含）",
              props: "departureTimeStart",
            },
            {
              title: "发车前时间区间止（不含）",
              props: "departureTimeEnd",
            },
            {
              title: "收取手续费比率",
              props: "chargeRate",
              SpecialJudgment: (res) => {
                return res + "%";
              },
            },
          ];
          this.renderDataB();
          break;
        case 2:
          this.titleName = [
            {
              title: "规则代码",
              props: "regulationCode",
            },
            {
              title: "规则说明",
              props: "regulationName",
            },
            {
              title: "值",
              props: "regulationValue",
            },
            {
              title: "单位",
              props: "unitType",
              SpecialJudgment: (res) => {
                return res === 0 ? "公里" : res === 1 ? "座" : "-";
              },
            },
          ];
          this.renderDataC();
          break;
      }
    },
    // 获取包车预订规则数据
    renderDataA() {
      getCharteredAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 获取包车退订规则数据
    renderDataB() {
      getRrefundTicketRegulationAPI({ channelNo: this.channelNo }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data;
          }
        }
      );
    },
    // 获取包车行车规则数据
    renderDataC() {
      drivingRegulationAPI({ channelNo: this.channelNo }).then((res) => {
        console.log(res);
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.carterConfig {
  height: 100%;
  .rule-tabs {
    width: 100%;
    border: 1px solid #dcdfe6;
    .rule-tabs-title {
      height: 40px;
      background: #f5f7fa;
      border-bottom: 1px solid #dcdfe6;
      .tabs {
        display: inline-block;
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        color: #909399;
        font-size: 14px;
        line-height: 40px;
        margin-left: -1px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      .tabs-active {
        border-bottom: 1px solid #ffffff;
        color: #409eff;
        background-color: #fff;
        border-right: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
      }
    }
    .rule-tabs-content {
      padding: 15px;
      background: #ffffff;
      .tabs-add {
        background: #336fee;
        color: #ffffff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
